<app-main-menu></app-main-menu>
<div class="row justify-content-center">
    <div class="alert alert-info" role="alert" style="width: 70%;">
        <b>À venir : </b>Possibilité d'archiver des messages reçu.
    </div>
</div>
<div class="row justify-content-center" >
    <ag-grid-angular center
                     style="height:900px;width: 900px"
                     class="ag-theme-alpine sign-tab"
                     [rowData]="elements"
                     [columnDefs]="columnDefs"
    ></ag-grid-angular>
</div>



<ng-template #see>
    <div class="modal-header">
        <p><span class="modal-signalement-titre">Signalement de {{element.prenom}} {{element.nom}} </span></p>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-12">
                <p><span class="modal-signalement">Type de signalement :</span> {{element.typeInfo}}</p>
                <p><span class="modal-signalement">Description :</span> {{element.description}}</p>

                <img style="max-width:100%" [src]="elementImage"/>
            </div>
        </div>

        <br/>

        <div class="row">
            <div class="col-md-6">
                <button class="btn btn-success btn-block bouton-repondre">Répondre à l'adminsitré</button>
            </div>
            <div class="col-md-6">
                <button class="btn btn-info btn-block bouton-transferer">Transférer</button>
            </div>
        </div>
    </div>
</ng-template>
