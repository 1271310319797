import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {AngularFireStorage} from '@angular/fire/storage';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AccountServiceService} from '../account-service.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-cell-custom-affect',
  templateUrl: './cell-custom-affect.component.html',
  styleUrls: ['./cell-custom-affect.component.css']
})
export class CellCustomAffectComponent implements OnInit {

  data:any;
  commune:any;
  constructor(
      private afAuth: AngularFireAuth,
      public afs: AngularFirestore,
      public router: Router,
      public storage: AngularFireStorage,
      private modalService: BsModalService,
      private accountServiceService: AccountServiceService,
      private renderer: Renderer2,
      private el: ElementRef,
  ) { }

  agInit(params){
    this.data = params;
    console.log(params.data)
  }


  ngOnInit(): void {
    this.commune = this.accountServiceService.getCommune();
  }

  delete_tag(i, num){
    console.log(i)
    const col = this.afs.collection('communes').doc(this.commune.id).collection('sms_numbers').doc(num);



    this.afs.collection('communes').doc(this.commune.id).collection('sms_send_list_' + i).doc(num).delete()

    col.update({
      tag_lists : firebase.firestore.FieldValue.arrayRemove(i)
    });
  }

}
