import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {AngularFireStorage} from '@angular/fire/storage';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AccountServiceService} from '../account-service.service';
import * as firebase from 'firebase';
import {FormArray, FormControl, FormGroup} from '@angular/forms';


@Component({
  selector: 'app-cell-custom-action-see',
  templateUrl: './cell-custom-action-see.component.html',
  styleUrls: ['./cell-custom-action-see.component.css']
})
export class CellCustomActionSeeComponent implements OnInit {

  constructor(
      private afAuth: AngularFireAuth,
      public afs: AngularFirestore,
      public router: Router,
      public storage: AngularFireStorage,
      private modalService: BsModalService,
      private accountServiceService: AccountServiceService,
      private renderer: Renderer2,
      private el: ElementRef,
  ) { }

  data:any;
  public modalRef: BsModalRef;
  public commune

  agInit(params){
    this.data = params.data;
    console.log(params.data)
  }


  fiForm = new FormGroup({
    prenom: new FormControl(),
    nom: new FormControl(),
    num: new FormControl(),
    email: new FormControl()
  });



  ngOnInit(): void {
    this.commune = this.accountServiceService.getCommune();
  }

  openModal(template){
    this.modalRef = this.modalService.show(template)
    this.fiForm.controls.prenom.setValue(this.data.prenom)
    this.fiForm.controls.nom.setValue(this.data.nom)
    this.fiForm.controls.num.setValue(this.data.num)
    this.fiForm.controls.num.disable()
    this.fiForm.controls.email.setValue(this.data.email)
  }

  closeModal(){
    this.modalRef.hide()
  }

  save(who){

    this.afs.collection('communes').doc(this.commune.id).collection('sms_numbers').doc(who).update({
      prenom : this.fiForm.controls.prenom.value,
      nom : this.fiForm.controls.nom.value,
      num : this.fiForm.controls.num.value,
      email : this.fiForm.controls.email.value
    })


  }

  del(who){
    // je récupère ses listes

    var usrRef = this.afs.collection('communes').doc(this.commune.id).collection('sms_numbers').doc(who)

    usrRef.valueChanges().subscribe((e)=>{

      console.log(e['tag_lists'])

      for(let elem of e['tag_lists']){

        console.log(elem)
        console.log(who)
        this.afs.collection('communes').doc(this.commune.id).collection('sms_send_list_' + elem).doc(who).delete()

      }
    });

    usrRef.delete()
  }








}
