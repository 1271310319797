import { Component, OnInit } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {Form, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AngularFireStorage} from '@angular/fire/storage';
import {finalize} from 'rxjs/operators';
import {observable, Observable, Operator} from 'rxjs';
import * as Quill from 'quill';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {Template} from '@angular/compiler/src/render3/r3_ast';
import {AccountServiceService} from '../account-service.service';
import * as moment from 'moment';
import {ThemePalette} from '@angular/material/core';
import { ElNotificationService } from 'element-angular'
import {isLineBreak} from 'codelyzer/angular/sourceMappingVisitor';




@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})



export class AdminComponent implements OnInit {

  constructor(
      private afAuth: AngularFireAuth,
      public afs: AngularFirestore,
      public router: Router,
      public storage: AngularFireStorage,
      private modalService: BsModalService,
      private accountServiceService: AccountServiceService,
      private formBuilder: FormBuilder,
      private notify: ElNotificationService,
  ) {


  }

  fiForm = new FormGroup({
    titre: new FormControl(),
    description: new FormControl(),
    smsContent: new FormControl(),
    checked: new FormArray([]),
    pubDate:  new FormControl(new Date())
  });




  ngOnInit() {
      this.coInit();
      this.getTags();
      this.getSmsNumbers();

      this.afs.collection('users', ref => ref.where('favoris', '==', {[this.commune.id]:this.commune.nom})).valueChanges().subscribe((e) => {
          this.followers = e
          this.followers_num = e.length
          console.log(e)
      })

      this.afs.collection('users').valueChanges().subscribe((f) => {
          this.followers_exact = f

      })

      this.formGroup = new FormGroup({

      }, { updateOn: 'change' });

  }



  public commune:any;
  public posts:any;
  public downloadURL: Observable<string>;
  fb;
  local_img;
  commune_id
  public modalRef: BsModalRef;
  public insee:any;
  public user:any;
  public isSent:boolean = false;

  public mairie_connect:boolean = false;
  public s_ms:boolean = false;
  public les_deux:boolean = false;

  public choix:boolean = true;

  public sms_tags:any;
  public sms_numbers:any;
  public followers
    public followers_num
    public followers_exact

  //public test:any;

  public smsLenghtTotal:any;
  public nbSms:any;


  //Debut DateTime Picker
    public date_brute: moment.Moment;
    public date : moment.Moment
    public disabled = false;
    public showSpinners = true;
    public showSeconds = false;
    public touchUi = false;
    public enableMeridian = false;
    public minDate: moment.Moment;
    public maxDate: moment.Moment;
    public stepHour = 1;
    public stepMinute = 1;
    public stepSecond = 1;
    public color: ThemePalette = 'primary';
    public disableMinute = false;
    public hideTime = false;

    public formGroup = new FormGroup({
        date: new FormControl(null, [Validators.required]),
        date2: new FormControl(null, [Validators.required])
    })
    public dateControl = new FormControl(new Date(2021,9,4,5,6,7));
    public dateControlMinMax = new FormControl(new Date());

    public options = [
        { value: true, label: 'True' },
        { value: false, label: 'False' }
    ];

    public listColors = ['primary', 'accent', 'warn'];

    public stepHours = [1, 2, 3, 4, 5];
    public stepMinutes = [1, 5, 10, 15, 20, 25];
    public stepSeconds = [1, 5, 10, 15, 20, 25];

    stringDateModel: string = new Date().toString();



    //Fin DateTime Picker



    oncheckChange(event){
      const formArray: FormArray = this.fiForm.get('checked') as FormArray;
      console.log(formArray)

        if(event.target.checked){
            // Add a new control in the arrayForm
            formArray.push(new FormControl(event.target.value));
        }
        /* unselected */
        else{
            // find the unselected element
            let i: number = 0;

            formArray.controls.forEach((ctrl: FormControl) => {
                if(ctrl.value == event.target.value) {
                    // Remove the unselected element from the arrayForm
                    formArray.removeAt(i);
                    return;
                }

                i++;
            });
        }
    }



  coInit(){
      console.log('depuis mon service : ' + this.accountServiceService.getInsee())
      this.insee = this.accountServiceService.getInsee()
      this.commune = this.accountServiceService.getCommune();
      console.log('Commune depuis service ' + this.accountServiceService.getCommune())
      //this.followers = this.accountServiceService.getNbFollow();
      /*this.getNbFollow();*/
  }


  addPhoto(event){
    const n = Date.now();
    console.log(event)
    const file = event.target.files[0]
    const filePath = this.commune.nom + '/' + n + '/' + 'img_flash_info'
    const fileRef = this.storage.ref(filePath)

    const task = this.storage.upload(filePath, file);
    task
        .snapshotChanges()
        .pipe(
            finalize(() => {
              this.downloadURL = fileRef.getDownloadURL();
              this.downloadURL.subscribe(url => {
                if (url) {
                  this.fb = url;
                  this.local_img = filePath;
                }
                console.log(this.local_img);

              });
            })
        )
        .subscribe(url => {
          if (url) {
            console.log(url);
          }
        });

  }

  publier() {
      if(this.fb != undefined){
          var lien_interne: any = this.local_img;
          var lien_externe: any = this.fb;
      }else{
          var lien_interne: any = '';
          var lien_externe: any = '';
      }
    var jour = new Date().getDate();
    var mois = ((new Date().getMonth() + 1) < 10 ? '0' : '') + (new Date().getMonth() + 1);
    var annee = new Date().getFullYear()
    var date = jour + '/' + mois + '/' + annee;


    console.log(this.fiForm.controls.titre.value)



    this.afs.collection('communes').doc(this.commune.id).collection('infos').add({
          titre: this.fiForm.controls.titre.value,
          desc: this.fiForm.controls.description.value,
          img: lien_interne,
          img_final_link: lien_externe,
          date: date,
          date_ts: Date.now(),
          is_displayed: true,
          commune: this.commune.id
        });

    this.isSent = true;
  }

    publier_later(){

        const time = this.fiForm.controls.pubDate.value
        time.setSeconds(0,0);
        const time_iso = time.toISOString()
        console.log(time_iso)

        if(this.fb != undefined){
            var lien_interne: any = this.local_img;
            var lien_externe: any = this.fb;
        }else{
            var lien_interne: any = '';
            var lien_externe: any = '';
        }
        var jour = time.getDate();
        var mois = ((time.getMonth() + 1) < 10 ? '0' : '') + (time.getMonth() + 1);
        var annee = time.getFullYear()
        var date = jour + '/' + mois + '/' + annee;


        console.log(this.fiForm.controls.titre.value)



        this.afs.collection('to_publish').doc(time_iso).collection('flash').add({
            titre: this.fiForm.controls.titre.value,
            desc: this.fiForm.controls.description.value,
            img: lien_interne,
            img_final_link: lien_externe,
            date: date,
            date_ts: Date.now(),
            is_displayed: true,
            commune: this.commune.id
        });

        this.isSent = true;


    }

    /*test(){
        var test = this.afs.collection('to_publish').doc('2021-04-23T16:15:00.000Z').collection('flash').valueChanges().subscribe((e)=>{
            console.log(e)
        })

    }*/

    publier_sms(){
        this.afs.collection('communes').doc(this.commune.id).collection('infos_sms').add({
            titre: this.fiForm.controls.titre.value,
            smsContent: this.fiForm.controls.smsContent.value,
            date_ts: Date.now(),
            to: this.fiForm.controls.checked.value
        });

        this.isSent = true;



        console.log(this.fiForm.controls.checked.value);

    }

  publier_2(){

      if(this.fb != undefined){
          var lien_interne = this.local_img
          var lien_externe = this.fb
      }/*else{
          var lien_interne = "";
          var lien_externe = "";
      }*/




      var jour = new Date().getDate();
      var mois = ((new Date().getMonth() + 1) < 10 ? '0' : '') + (new Date().getMonth() + 1);
      var annee = new Date().getFullYear()
      var date = jour + '/' + mois + '/' + annee;

      this.afs.collection('communes').doc(this.commune.id).collection('infos').add({
          titre: this.fiForm.controls.titre.value,
          desc: this.fiForm.controls.description.value,
          img: lien_interne,
          img_final_link: lien_externe,
          date: date,
          date_ts: Date.now(),
          is_displayed: true
      });



      this.afs.collection('communes').doc(this.commune.id).collection('infos_sms').add({
          titre: this.fiForm.controls.titre.value,
          smsContent: this.fiForm.controls.smsContent.value,
          date_ts: Date.now()
      });


  }



  openModal(template, from){

        if(from == 1){
            console.log(this.fiForm.controls.titre.value)

            if(this.fiForm.controls.description.value===null){
                console.log('pas de contenu')
                this.notify.show("Merci de mettre du contenu dans le flash info", 'Impossible de publier');
            }

            if(this.fiForm.controls.titre.value===null || this.fiForm.controls.titre.value===''){
                console.log('pas de titre')
                this.notify.show("Merci de mettre un titre au flash info", 'Impossible de publier');
            }

            if(this.fiForm.controls.titre.value===null || this.fiForm.controls.description.value===null || this.fiForm.controls.titre.value===''){

            }else{
                this.modalRef = this.modalService.show(template)
            }
        }else{
            this.modalRef = this.modalService.show(template)
        }

  }

    chageState(v){
      if(v==1){
          console.log('MC')
          this.mairie_connect = true;
          this.choix = false;
      }

        if(v==2){
            console.log(this.commune)
            if(this.commune.uses_sms === false){
                this.notify.show("Vous devez souscrire à l'option SMS");
            }else{
                console.log('SMS')
                this.s_ms = true;
                this.choix = false;
            }

        }

        if(v==3){
            console.log('LES DEUX')
            this.les_deux = true;
            this.choix = false;
        }


  }
    getTags() {
        this.afs.collection('communes').doc(this.commune.id).collection('sms_tags').valueChanges({idField: 'propertyId'})
            .subscribe((e) =>{
                this.sms_tags = e
                /*e.forEach((a) => {
                    console.log(a['value'])

                    this.afs.collection('communes').doc(this.commune.id).collection('sms_send_list_' + a['value']).valueChanges({idField: 'propertyId'})
                        .subscribe((q) =>{
                            console.log(q)
                        })

                })*/
            })

    }

    getSmsNumbers(){
        this.afs.collection('communes').doc(this.commune.id).collection('sms_numbers',ref => ref.where('tag_lists', '==', 'Administré'))
            .valueChanges().subscribe((e) => {
                console.log(e)
        })
    }

    getNbFollow(){

    }

    countCaractSms(){
        var headerandlegal = 'Commune de ' + this.commune.nom + '\n\n' + this.fiForm.controls.smsContent.value.length + '\n\nSTOP SMS :' + this.commune.mairie_tel
        var smsContentLenght = this.fiForm.controls.smsContent.value.length

        this.smsLenghtTotal = headerandlegal.length + smsContentLenght;

        this.countNumSms(this.smsLenghtTotal)

    }

    countNumSms(smsLeng){
        if(smsLeng < 149) {
            this.nbSms = 1
        }
        if(smsLeng>150 && smsLeng<300){
            this.nbSms = 2
        }
        if(smsLeng>300 && smsLeng<450){
            this.nbSms = 3
        }
        if(smsLeng>450 && smsLeng<600){
            this.nbSms = 4
        }
        if(smsLeng>600 && smsLeng<750){
            this.nbSms = 5
        }
        if(smsLeng>750 && smsLeng<99999999){
            this.nbSms = "SMS TROP LONG"
        }

    }

    moreInfoSms(){
        this.notify.show("Pour plus d'informations sur le tarif SMS, contactez le 06 86 16 04 45");
    }




  config = {
      toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['link']
      ]
  }

    sms = {
        toolbar: [

        ]
    }




}
