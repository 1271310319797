<app-main-menu></app-main-menu>

<div class="home">
    <div class="row list-posts" *ngFor="let post of posts ; let i = index" [class]="(i % 2 == 0) ? 'paire' : 'impaire'">



        <div class="col-md-7">
            <img *ngIf="post.img!=''" style="max-width:200px" [src]="post.img_final_link" class="mr-3" alt="...">
            

            <div class="media-body">
                <h5 class="mt-0 flash-date">{{post.date}}</h5>
                <h5 class="mt-0">{{post.titre}}</h5>
                <div [innerHTML]="post.desc"></div>

                <button (click)="desactiverPost(post)" *ngIf="post.is_displayed==true" type="button" class="btn btn-primary btn-sm desactiver">
                    <svg width="3em" height="3em" viewBox="0 0 16 16" class="bi bi-toggle-on" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M5 3a5 5 0 0 0 0 10h6a5 5 0 0 0 0-10H5zm6 9a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"/>
                </svg></button>
                <button (click)="activerPost(post)" *ngIf="post.is_displayed==false" type="button" class="btn btn-success btn-sm activer"><svg width="3em" height="3em" viewBox="0 0 16 16" class="bi bi-toggle-off" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M11 4a4 4 0 0 1 0 8H8a4.992 4.992 0 0 0 2-4 4.992 4.992 0 0 0-2-4h3zm-6 8a4 4 0 1 1 0-8 4 4 0 0 1 0 8zM0 8a5 5 0 0 0 5 5h6a5 5 0 0 0 0-10H5a5 5 0 0 0-5 5z"/>
                </svg></button>
                <button (click)="deletePost(post)" *ngIf="post.is_displayed==false" type="button" class="btn btn-danger btn-sm">Supprimer définitivement</button>
            </div>

        </div>

    </div>
</div>
