import { Component, OnInit } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {Form, FormArray, FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AngularFireStorage} from '@angular/fire/storage';
import {finalize} from 'rxjs/operators';
import {observable, Observable, Operator} from 'rxjs';
import * as Quill from 'quill';
import {MainMenuComponent} from '../main-menu/main-menu.component';

import {faSync} from '@fortawesome/free-solid-svg-icons';
import {faListOl} from '@fortawesome/free-solid-svg-icons';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {faSms} from '@fortawesome/free-solid-svg-icons';
import {faUsers} from '@fortawesome/free-solid-svg-icons';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import {Template} from '@angular/compiler/src/render3/r3_ast';
import {AccountServiceService} from '../account-service.service';
@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit {

  constructor(
      private afAuth: AngularFireAuth,
      public afs: AngularFirestore,
      public router: Router,
      public storage: AngularFireStorage,
      private modalService: BsModalService,
      private accountServiceService: AccountServiceService,
      private formBuilder: FormBuilder
  ) {


  }

  //icons
  faSync = faSync;
  faListOl = faListOl;
  faInfoCircle = faInfoCircle;
  faSms = faSms;
  fausers = faUsers;

  followers:any;
  followers_only:any;
  follower_global:any;
  loc_fav:any;

  insee:any
  commune:any

  pc:any

  pForm = new FormGroup({

    nom: new FormControl(),
    insee: new FormControl(),
    cp: new FormControl(),
    desc: new FormControl(),
    nbHabs: new FormControl(),
    lundi: new FormControl(),
    mardi: new FormControl(),
    mercredi: new FormControl(),
    jeudi: new FormControl(),
    vendredi: new FormControl(),
    samedi: new FormControl(),
    mail: new FormControl(),
    tel: new FormControl(),
  });

  ngOnInit(): void {
    this.coInit()
  }

  coInit(){
    console.log('depuis mon service : ' + this.accountServiceService.getInsee())
    this.insee = this.accountServiceService.getInsee()
    this.commune = this.accountServiceService.getCommune();
    console.log('Commune depuis service ' + this.accountServiceService.getCommune())
    //this.followers = this.accountServiceService.getNbFollow();
    /*this.getNbFollow();*/


    //setFormValues
    this.pForm.controls.nom.setValue(this.commune.nom)
    this.pForm.controls.nom.disable()
    this.pForm.controls.insee.setValue(this.commune.insee)
    this.pForm.controls.insee.disable()
    this.pForm.controls.cp.setValue(this.commune.cp)
    this.pForm.controls.cp.disable()
    this.pForm.controls.desc.setValue(this.commune.desc)
    this.pForm.controls.nbHabs.setValue(this.commune.nb_hab)
    this.pForm.controls.nbHabs.disable()
    this.pForm.controls.lundi.setValue(this.commune.mairie_lundi)
    this.pForm.controls.mardi.setValue(this.commune.mairie_mardi)
    this.pForm.controls.mercredi.setValue(this.commune.mairie_mercredi)
    this.pForm.controls.jeudi.setValue(this.commune.mairie_jeudi)
    this.pForm.controls.vendredi.setValue(this.commune.mairie_vendredi)
    this.pForm.controls.samedi.setValue(this.commune.mairie_samedi)
    this.pForm.controls.mail.setValue(this.commune.mairie_mail)
    this.pForm.controls.tel.setValue(this.commune.mairie_tel)
  }





  update_profil(){
    this.afs.collection('communes').doc(this.commune.id).update({
      nom : this.pForm.controls.nom.value,
      insee : this.pForm.controls.insee.value,
      cp : this.pForm.controls.cp.value,
      desc : this.pForm.controls.desc.value,
      nb_hab : this.pForm.controls.nbHabs.value,
      mairie_lundi : this.pForm.controls.lundi.value,
      mairie_mardi : this.pForm.controls.mardi.value,
      mairie_mercredi : this.pForm.controls.mercredi.value,
      mairie_jeudi : this.pForm.controls.jeudi.value,
      mairie_vendredi : this.pForm.controls.vendredi.value,
      mairie_samedi : this.pForm.controls.samedi.value,
      mairie_mail : this.pForm.controls.mail.value,
      mairie_tel : this.pForm.controls.tel.value

    })
  }





}
