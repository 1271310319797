<app-main-menu></app-main-menu>
<app-sub-menu-settings></app-sub-menu-settings>

<br/>

<div class="row">
    <div class="col-md-12">
        <h1>Nombre d'abonnés à votre commune : </h1>
    </div>

</div>

<div class="row">

    <div class="col-md-3 grey">
        <h1 class="nombre">{{followers_only}}</h1>
        <h5>Personnes suivent uniquement {{commune.nom}}</h5>
    </div>

    <div class="col-md-3 grey">
        <h1 class="nombre">{{follower_global}}</h1>
        <h5>Personnes suivent {{commune.nom}} et d'autres communes</h5>
    </div>



</div>


<div class="row">
    <div class="col-md-12">
        <h1>Nombre d'abonnés aux alertes SMS : </h1>
    </div>

</div>

<div class="row">

    <div class="col-md-3 grey">
        <h1 class="nombre">{{sms_followers}}</h1>
        <h5>Personnes sont inscrites au SMS de {{commune.nom}}</h5>
    </div>
    
</div>
