import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {AppComponent} from './app.component';
import {AdminComponent} from './admin/admin.component';
import {FlashsComponent} from './flashs/flashs.component';
import {SignalementsComponent} from './signalements/signalements.component';
import {SmslistComponent} from './smslist/smslist.component';
import {ProfilComponent} from './profil/profil.component';
import {SettingStatComponent} from './setting-stat/setting-stat.component';
import {SettingSmsComponent} from './setting-sms/setting-sms.component';
import {SettingParticipationComponent} from './setting-participation/setting-participation.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: 'admin', component: AdminComponent},
  { path: 'flash', component: FlashsComponent},
  { path: 'participation', component: SignalementsComponent},
  { path: 'smslist', component: SmslistComponent},
  { path: 'profil', component: ProfilComponent},
  { path: 'setting-stat', component: SettingStatComponent},
  { path: 'setting-sms', component: SettingSmsComponent},
  { path: 'setting-pc', component: SettingParticipationComponent},
  { path: '', redirectTo : '/login', pathMatch: 'full' }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash : true})],
  exports: [RouterModule]
})

export class AppRoutingModule {}
