<app-main-menu></app-main-menu>
<app-sub-menu-settings></app-sub-menu-settings>

<br/>


<div class="row">

    <div class="col-md-5 grey">

        Utiliser la participation citoyenne ? : <ui-switch [ngModel]="pc" (change)="changePP($event)"></ui-switch>


    </div>

    <div class="col-md-5 grey">

        <h4>Qui notifier en cas de participation citoyenne ?</h4>


        <span *ngFor="let u of user">
            <input type="checkbox" (change)="ppNotif($event, u['email'])" [ngModel]="u.pp_notif"> {{u['email']}} <br/>
        </span>


    </div>

</div>
