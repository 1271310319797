import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies';

@Injectable({
  providedIn: 'root'
})
export class AccountServiceService {

  insee;
  commune;
  nb_follow:any;

  constructor() {

  }

  setInsee(a){
    this.insee = a
    Cookie.set('insee', a);
  }
  getInsee(){
    return this.insee
  }

  setCommune(b) {
    this.commune = b
    Cookie.set('commune', JSON.stringify(b))
  }
  getCommune(){
    var gC = Cookie.get('commune')
    return JSON.parse(gC)

  }

  setNbFollow(c){
    this.nb_follow = c
  }

  getNbFollow(){
    return this.nb_follow
  }


}
