import { Component, OnInit } from '@angular/core';

import {faSync} from '@fortawesome/free-solid-svg-icons';
import {faListOl} from '@fortawesome/free-solid-svg-icons';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {faSms} from '@fortawesome/free-solid-svg-icons';
import {faUsers} from '@fortawesome/free-solid-svg-icons';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {AngularFireStorage} from '@angular/fire/storage';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AccountServiceService} from '../account-service.service';
import {FormBuilder} from '@angular/forms';
import { Cookie } from 'ng2-cookies';


@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {

  public commune : any;

  constructor(
      private afAuth: AngularFireAuth,
      public afs: AngularFirestore,
      public router: Router,
      public storage: AngularFireStorage,
      private modalService: BsModalService,
      private accountServiceService: AccountServiceService,
      private formBuilder: FormBuilder
  ) {


  }

  ngOnInit(): void {
    this.commune = this.accountServiceService.getCommune();
  }


  goToFlashs(){
    this.router.navigate(['flash']);
  }

  goToParticipation(){
    this.router.navigate(['participation']);
  }

  goToAdmin(){
    this.router.navigate(['admin']);
  }

  goToSmsList(){
    this.router.navigate(['smslist']);
  }

  goToProfil(){
    this.router.navigate(['profil']);
  }


  deco(){
    console.log('bye')
    Cookie.deleteAll()
    Cookie.delete('commune', '/', 'admin.mairieconnect.com')

    localStorage.clear()
    return this.afAuth.signOut().then(()=>{
      this.router.navigate(['login'])
    });



  }

  goToSettingSms(){
    this.router.navigate(['setting-sms']);
  }

}
