<app-main-menu></app-main-menu>
<app-sub-menu-settings></app-sub-menu-settings>


<br/>



<br/>

<div class="row">

    <div class="col-md-5 grey">
        <br/>
        <h3>Informations Générales</h3>
        Nom de la commune : <input class="input_profil" [disabled]="true"  [formControl]="pForm.controls.nom" /><br>
        Code INSEE : <input  class="input_profil" [disabled]="true" [formControl]="pForm.controls.insee" /><br>
        Code postal : <input class="input_profil" [disabled]="true"  [formControl]="pForm.controls.cp"/><br>
        Description commune : <input [formControl]="pForm.controls.desc" class="input_profil" [disabled]="false"/><br><br>
        Habitants : <input class="input_profil" [disabled]="true"  [formControl]="pForm.controls.nbHabs"/><br><br>



    </div>

    <div class="col-md-5 grey">
        <h3>Horaires de la Mairie</h3>
        Lundi : <input class="input_profil" [disabled]="false" [formControl]="pForm.controls.lundi"/><br>
        Mardi : <input class="input_profil" [disabled]="false"  [formControl]="pForm.controls.mardi"/><br>
        Mercredi : <input class="input_profil" [disabled]="false"  [formControl]="pForm.controls.mercredi"/><br>
        Jeudi : <input class="input_profil" [disabled]="false"  [formControl]="pForm.controls.jeudi"/><br>
        Vendredi : <input class="input_profil" [disabled]="false"  [formControl]="pForm.controls.vendredi"/><br>
        Samedi : <input class="input_profil" [disabled]="false"  [formControl]="pForm.controls.samedi"/><br>
        <h3 class="horaire">Contact Mairie</h3>
        Mail : <input class="input_profil" [disabled]="false"  [formControl]="pForm.controls.mail"/><br>
        Téléphone : <input class="input_profil" [disabled]="false" [formControl]="pForm.controls.tel"/><br><br>

        <button (click)="update_profil()" type="btn" class="btn btn-success">Mettre à jour</button>
    </div>

</div>

<br/>


