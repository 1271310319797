<button class="btn btn-success bouton-profil" (click)="openModal(see); seen()">Voir</button>

<ng-template #see>
    <div id="seeModale">
        <div class="modal-header">
            Participation Citoyenne de {{data.prenom}} {{data.nom}}
        </div>

        <div class="modal-body">
            Prénom : {{data.prenom}}<br/>
            Nom : {{data.nom}}<br/>
            Message : {{data.description}}<br/>
            <img width="400px" [src]="elementImage"><br/>
        </div>

        <div style="margin-bottom: 20px" class="row justify-content-center">
            <div class="col-sm-3"><button class="btn btn-success bouton-profil" (click)="openModal(see2)">Répondre</button></div>
            <div class="col-sm-3"><button class="btn btn-success bouton-profil" (click)="imprimer('seeModale')">Imprimer</button></div>
        </div>
    </div>

</ng-template>


<ng-template #see2>
    <div class="modal-header">
        Répondre à {{data.prenom}} {{data.nom}}
    </div>

    <div class="modal-body">
        A : <input [disabled]="true" [value]="data.manualMail"><br/>
        CC : <input [value]="commune.mairie_mail"><br/>
        DE : <input [value]="commune.mairie_mail"><br/>

        <quill-editor [formControl]="fiForm.controls.html" [modules]="config"></quill-editor> <br/><br/>

        <button (click)="sendMail(data.manualMail, data.description, data)" class='btn btn-success bouton-profil'>Répondre à l'administrée</button>
    </div>


</ng-template>

