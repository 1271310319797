import { Component, OnInit, ElementRef, HostListener, AfterViewInit, ViewChild, ChangeDetectorRef} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {AngularFireStorage} from '@angular/fire/storage';

import {  MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as firebase from 'firebase';
import {AccountServiceService} from '../account-service.service';
import {CellCustomComponent} from '../cell-custom/cell-custom.component';
import {CellCustomAffectComponent} from '../cell-custom-affect/cell-custom-affect.component';
import {CellCustomActionSeeComponent} from '../cell-custom-action-see/cell-custom-action-see.component';

import {CellCustomEditPcComponent} from '../cell-custom-edit-pc/cell-custom-edit-pc.component';


@Component({
  selector: 'app-signalements',
  templateUrl: './signalements.component.html',
  styleUrls: ['./signalements.component.css']
})
export class SignalementsComponent implements OnInit{
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('row', { static: true }) row: ElementRef;




  public commune: any;
  public user: any;
  private usermail: any;
  public elements: any;

  public modalRef: any;

  headElements = ['prenom', 'nom', 'Type', 'Action'];

  public element:any;
  public elementImage:any;
  columnDefs:any;

  constructor(
      private afAuth: AngularFireAuth,
      public afs: AngularFirestore,
      public router: Router,
      public storage: AngularFireStorage,
      private cdRef: ChangeDetectorRef,
      private modalService: BsModalService,
      private accountServiceService: AccountServiceService,
  ) { }


  ngOnInit(): void {

    this.commune = this.accountServiceService.getCommune();
    this.getUser()
    this.loadDT();

  }


  getUser(){
    this.afs.collection('participation_citoyenne').doc(this.commune.cp).collection('signalements').valueChanges({idField: 'propertyId'})
        .subscribe((e)=>{
          this.elements = e
          console.log(e)
        });
  }

  ngAfterViewInit() {
    console.log(this.elements)

  }

  //*********** MENU MENU MENU

  goToFlashs(){
    this.router.navigate(['flash']);
  }

  goToParticipation(){
    this.router.navigate(['participation']);
  }

  goToAdmin(){
    this.router.navigate(['admin']);
  }

  goToSmsList(){
    this.router.navigate(['smslist']);
  }
  goToProfil(){
    this.router.navigate(['profil']);
  }


  loadDT(){

    this.columnDefs = [
      {headerName : "Date", field : "date", width:120, sortable:true,resizable:true, filter:'agTextColumnFilter', filterParams:{newRowsAction:'keep'}},
      {headerName : "Prénom", field : "prenom", width:120, sortable:true,resizable:true, filter:'agTextColumnFilter', filterParams:{newRowsAction:'keep'}},
      {headerName : "Nom", field : "nom", width:120, sortable:true,resizable:true, filter:'agTextColumnFilter', filterParams:{newRowsAction:'keep'}},
      {headerName : "E Mail", field : "manualMail", width:140, sortable:true,resizable:true, filter:'agTextColumnFilter', filterParams:{newRowsAction:'keep'}},
      /*{headerName : "Description", field : "description", width:120, sortable:true,resizable:true, filter:'agTextColumnFilter', filterParams:{newRowsAction:'keep'}},*/
      {headerName : "Lu", field : "read", width:90, sortable:true,resizable:true, filter:'agTextColumnFilter', filterParams:{newRowsAction:'keep'}},
      {headerName : "Répondu", field : "answer", width:90, sortable:true,resizable:true, filter:'agTextColumnFilter', filterParams:{newRowsAction:'keep'}},
      {headerName : "Action", field : "Action", resizable:true, sortable:false, cellRendererFramework: CellCustomEditPcComponent},

    ];
  }



}
