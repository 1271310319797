<!--<header>
    <nav class="navbar navbar-expand-lg navbar-light bg-light menu-principal">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarText">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item">
                    <a class="nav-link" (click)="goToAdmin()"><b>Publier Flash Info</b><span class="sr-only"></span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="goToFlashs()">Voir Flash Info<span class="sr-only"></span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="goToSmsList()">Mes Listes SMS<span class="sr-only"></span></a>
                </li>
                <li class="nav-item">
                    <button (click)="goToParticipation()" type="button" class="btn btn-primary bouton-participation">Participation Citoyenne</button>
                </li>
                <li class="nav-item">
                    <button (click)="goToProfil()" type="button" class="btn btn-success bouton-profil">Réglages & Infos</button>
                </li>&nbsp;
                <li class="nav-item">
                    <button (click)="deco()" type="button" class="btn btn-danger">Déconnexion</button>
                </li>&nbsp;

            </ul>

            <span class="navbar-text">Commune de <b>{{commune.nom}}</b>


    </span>
        </div>
    </nav>

</header>-->

<header>
<!--Navbar-->
<mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark purple lighten-1" class="menu-principal" [containerInside]="false">

    <!-- Navbar brand -->
    <mdb-navbar-brand><a class="navbar-brand" (click)="goToAdmin()">Admin Mairie Connect</a></mdb-navbar-brand>

    <!-- Collapsible content -->
    <links>

        <!-- Links -->
        <ul class="navbar-nav mr-auto">
            <!-- Dropdown -->
            <li class="nav-item dropdown" dropdown>
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                    Flash Info ou SMS <span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" mdbWavesEffect (click)="goToAdmin()">Publier un Flash Info</a>
                    <a class="dropdown-item waves-light" mdbWavesEffect (click)="goToFlashs()">Voir mes Flash Info</a>
                    <!--<div class="divider dropdown-divider"></div>
                    <a class="dropdown-item waves-light" mdbWavesEffect href="#">Separated link</a>-->
                </div>
            </li>


            <!-- Dropdown -->
            <li class="nav-item dropdown" dropdown>
                <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                    Gestion SMS<span class="caret"></span></a>
                <div *dropdownMenu class="dropdown-menu dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" mdbWavesEffect (click)="goToSmsList()">Gestion des abonnés</a>
                    <div class="divider dropdown-divider"></div>
                    <a class="dropdown-item waves-light" mdbWavesEffect (click)="goToSettingSms()">Paramètres SMS</a>
                </div>
            </li>


            <li class="nav-item">
                <a class="nav-link waves-light" mdbWavesEffect (click)="goToParticipation()">Participation Citoyenne<span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link waves-light" mdbWavesEffect (click)="goToProfil()">Réglages et Infos</a>
            </li>



        </ul>
        <!-- Links -->

        <ul class="navbar-nav ml-auto nav-flex-icons">

            <li class="nav-item">
                <a class="nav-link waves-light btn btn-danger" mdbWavesEffect (click)="deco()">Déconnexion</a>
            </li>&nbsp;
            <li class="nav-item">
                <span class="navbar-text">Commune de <b>{{commune.nom}}</b></span>
            </li>

            <!--<li class="nav-item">
                <a class="nav-link">1 <mdb-icon fas icon="envelope"></mdb-icon></a>
            </li>-->
            <!--<li class="nav-item avatar dropdown" dropdown>
                a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light" mdbWavesEffect>
                    <img src="https://mdbootstrap.com/img/Photos/Avatars/avatar-2.jpg" alt="" class="img-fluid rounded-circle z-depth-0"></a>
                <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
                    <a class="dropdown-item waves-light" mdbWavesEffect href="#">My account</a>
                    <a class="dropdown-item waves-light" mdbWavesEffect href="#">Log out</a>
                </div>
            </li>-->
        </ul>
    </links>
    <!-- Collapsible content -->

</mdb-navbar>
</header>
<!--/.Navbar-->
