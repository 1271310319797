import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AngularFireAuth} from '@angular/fire/auth';
import {Router} from '@angular/router';
import {AngularFirestore} from '@angular/fire/firestore';
import * as firebase from 'firebase';
import {AccountServiceService} from '../account-service.service';
import {Cookie} from 'ng2-cookies';
export default firebase

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, AfterViewInit {

  public email:any;
  public password:any;
  public cc:any;
  public commune:any;

  constructor(
      private afAuth: AngularFireAuth,
      private router: Router,
      public afs: AngularFirestore,
      private accountServiceService: AccountServiceService
  ) { }

  registerForm = new FormGroup({
    email: new FormControl(),
    password: new FormControl(),
    code_commune: new FormControl()
  });

  ngOnInit(): void {
    console.log('login')
    Cookie.delete('commune', '/', 'localhost')
  }

  ngAfterViewInit(): void {
    Cookie.delete('commune')
  }


  findCommune(){

    var cc = this.registerForm.controls.code_commune.value;

    this.accountServiceService.setInsee(cc)



    //Je récupère la commune avec ce code INSEE

    // Je parcours le tableau des user pour avois la corespondance


    this.afAuth.authState.subscribe((user) =>{
      this.afs.collection('communes', ref => ref.where('insee', '==', cc))
          .valueChanges({idField: 'id'}).subscribe((e) => {
            this.accountServiceService.setCommune(e[0])
            this.commune = e[0]
          }

      )


    }, (error) => {
      console.log(error)
    });

  }

  logToCommune(commune){

    console.log(commune.id)
    const email = this.registerForm.controls.email.value;
    var password = this.registerForm.controls.password.value;
    var cc = commune.insee

    var docRef = this.afs.collection('communes').doc(commune.id).collection('user').doc(email).valueChanges().subscribe((r) => {
      if(r != undefined){
        //process de co avec r

        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).then((e)=>{
          console.log(e)
          this.afAuth.signInWithEmailAndPassword(email, password).then((sucess)=>{
            console.log(sucess)
            this.router.navigate(['admin'], {state : {code: cc, user: r}});
          })
        })



      }else{
        alert('Nous ne vous trouvons pas dans la base des utilisateurs')
      }
    })







  }

}
