import { BrowserModule } from '@angular/platform-browser';
import {NgModule, OnInit} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './admin/admin.component';
import { LoginComponent } from './login/login.component';


//Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {QuillModule} from 'ngx-quill';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import {TabsModule} from 'ngx-bootstrap/tabs';
import { FlashsComponent } from './flashs/flashs.component';
import { SignalementsComponent } from './signalements/signalements.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { SmslistComponent } from './smslist/smslist.component';
import { ProfilComponent } from './profil/profil.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';

import {AgGridModule} from 'ag-grid-angular';
import { CellCustomComponent } from './cell-custom/cell-custom.component';
import { CellCustomAffectComponent } from './cell-custom-affect/cell-custom-affect.component';
import { CellCustomActionDeleteComponent } from './cell-custom-action-delete/cell-custom-action-delete.component';
import { CellCustomActionSeeComponent } from './cell-custom-action-see/cell-custom-action-see.component';

import { UiSwitchModule } from 'ngx-ui-switch';
import { CellCustomEditPcComponent } from './cell-custom-edit-pc/cell-custom-edit-pc.component';

import {Buffer} from 'buffer';
import {NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule} from '@angular-material-components/datetime-picker';
import {Moment} from 'moment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { SubMenuSettingsComponent } from './sub-menu-settings/sub-menu-settings.component';
import { SettingStatComponent } from './setting-stat/setting-stat.component';
import { SettingSmsComponent } from './setting-sms/setting-sms.component';
import { SettingParticipationComponent } from './setting-participation/setting-participation.component';
import { ElModule } from 'element-angular';


var firebaseConfig = {
  apiKey: "AIzaSyCxrBCIWqb6O6puJcfOPFiAGByqR7oezuw",
  authDomain: "mairieconnect.firebaseapp.com",
  databaseURL: "https://mairieconnect.firebaseio.com",
  projectId: "mairieconnect",
  storageBucket: "mairieconnect.appspot.com",
  messagingSenderId: "19871056582",
  appId: "1:19871056582:web:90e6ddef57b08a09598dc7",
  measurementId: "G-NNDCLPWLRZ"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();


@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    LoginComponent,
    FlashsComponent,
    SignalementsComponent,
    SmslistComponent,
    ProfilComponent,
    CellCustomComponent,
    CellCustomAffectComponent,
    CellCustomActionDeleteComponent,
    CellCustomActionSeeComponent,
    CellCustomEditPcComponent,
    MainMenuComponent,
    SubMenuSettingsComponent,
    SettingStatComponent,
    SettingSmsComponent,
    SettingParticipationComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AngularFireModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFireModule.initializeApp(firebaseConfig),
        FormsModule,
        ReactiveFormsModule,
        QuillModule.forRoot(),
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        TabsModule,
        MDBBootstrapModule.forRoot(),
        FontAwesomeModule,
        AgGridModule,
        UiSwitchModule.forRoot({
            size: 'small',
            color: 'rgb(0, 189, 99)',
            switchColor: '#80FFA2',
            defaultBgColor: '#EB5644',
            defaultBoColor : '#476EFF',
            checkedLabel: 'oui',
            uncheckedLabel: 'non'
        }),
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        NgxMatTimepickerModule,
        BrowserAnimationsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        ElModule.forRoot(),

    ],
  providers: [],
    entryComponents: [CellCustomComponent],
  bootstrap: [AppComponent]
})
export class AppModule{



}
