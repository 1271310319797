import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {AngularFireStorage} from '@angular/fire/storage';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AccountServiceService} from '../account-service.service';
import * as firebase from 'firebase';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {Buffer} from 'buffer';

@Component({
  selector: 'app-cell-custom-edit-pc',
  templateUrl: './cell-custom-edit-pc.component.html',
  styleUrls: ['./cell-custom-edit-pc.component.css']
})
export class CellCustomEditPcComponent implements OnInit {

  data:any;
  public modalRef: BsModalRef;
  public commune



  agInit(params){
    this.data = params.data;
    console.log(params.data)
  }

  elementImage:any

  constructor(
      private afAuth: AngularFireAuth,
      public afs: AngularFirestore,
      public router: Router,
      public storage: AngularFireStorage,
      private modalService: BsModalService,
      private accountServiceService: AccountServiceService,
      private renderer: Renderer2,
      private el: ElementRef,
  ) { }
  fiForm = new FormGroup({
    html: new FormControl()
  });

  ngOnInit(): void {
    this.commune = this.accountServiceService.getCommune();
  }





  openModal(template){
    console.log(this.commune.cp)
    console.log(this.data)

    this.afs.collection('participation_citoyenne').doc(this.commune.cp).collection('signalements').doc(this.data.propertyId).valueChanges().subscribe((i)=>{

      console.log(i['lienImage'])
      var storage = firebase.storage()
      var pathRef = storage.ref(i['lienImage'])

      pathRef.getDownloadURL().then((url)=>{
        this.elementImage = url
        console.log(url)
      })

      console.log(this.elementImage)

    });
    console.log(template)




    this.modalRef = this.modalService.show(template)

  }

  config = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link']
    ]
  }

  sendMail(to, messageInitial, data){
    const payload = {
      html : '<p>Votre message : ' + messageInitial + '</p> <br/>' + this.fiForm.controls.html.value,
      plaintext : '<p>Votre message : ' + messageInitial + '</p> <br/>' + this.fiForm.controls.html.value,
      subject : 'Réponse de ' + this.commune.nom,
      from : this.commune.mairie_mail,
      reply : this.commune.mairie_mail,
      recipients: [{ address : to }, { address : this.commune.mairie_mail }]
    };

    const apiToken = "JFn3WhEMRQmeGtxa_XB5WITOeSrt4bA5z9Iu3l_eEfJuXiXH29h-MRibXcHw3YS1";
    const encodedAuth = Buffer.from(`${apiToken}:`).toString("base64");

    const resp = fetch("https://gatewayapi.com/rest/email", {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        Authorization: `Basic ${encodedAuth}`,
        "Content-Type": "application/json",
      },
    });

    console.log(data)

    this.afs.collection('participation_citoyenne').doc(this.commune.cp).collection('signalements').doc(data.propertyId).update({
      answer : 'Oui',
    })


    this.modalRef.hide()
  }

  seen(){
    console.log('lu')
    this.afs.collection('participation_citoyenne').doc(this.commune.cp).collection('signalements').doc(this.data.propertyId).update({
      read : 'Oui',
    })
  }

  imprimer(elementId){
    const printContent = document.getElementById(elementId);
    const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    WindowPrt.document.write(printContent.innerHTML);
    WindowPrt.document.close();
    WindowPrt.focus();
    WindowPrt.print();
  }
}
