<button type="button" class="btn btn-success bouton-profil" (click)="openModal(template)">Editer</button>


<ng-template #template>



    <div class="modal-header">
        Editer {{data.prenom}} {{data.nom}}
    </div>

    <div class="modal-body">
        <form>
            <div class="form-group">
                <label>Prénom</label>
                <input type="text" [formControl]="fiForm.controls.prenom" class="form-control">
            </div>

            <div class="form-group">
                <label>Nom</label>
                <input type="text" [formControl]="fiForm.controls.nom" class="form-control">
            </div>

            <div class="form-group">
                <label>Mobile</label>
                <input type="text"  [formControl]="fiForm.controls.num" class="form-control">
            </div>

            <div class="form-group">
                <label>Email</label>
                <input type="text" [formControl]="fiForm.controls.email" class="form-control">
            </div>
        </form>

        <button type="button" class="btn btn-primary" (click)="save(data.num)">Enregistrer</button>
        <button type="button" class="btn btn-danger" (click)="del(data.num)">Supprimer</button>
    </div>
</ng-template>
