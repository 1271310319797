import {Component, ElementRef, OnInit, ViewChild, Renderer2} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {AngularFireStorage} from '@angular/fire/storage';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {AccountServiceService} from '../account-service.service';
import * as firebase from 'firebase';

import {  MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';
import {appendHtml} from 'ag-grid-community/dist/lib/utils/dom';
import {toHtml} from '@fortawesome/fontawesome-svg-core';
import {filter} from 'ag-grid-community/dist/lib/utils/array';

import {CellCustomComponent} from '../cell-custom/cell-custom.component';
import {CellCustomAffectComponent} from '../cell-custom-affect/cell-custom-affect.component';
import {CellCustomActionDeleteComponent} from '../cell-custom-action-delete/cell-custom-action-delete.component';
import {CellCustomActionSeeComponent} from '../cell-custom-action-see/cell-custom-action-see.component';
import {count} from 'rxjs/operators';

@Component({
  selector: 'app-smslist',
  templateUrl: './smslist.component.html',
  styleUrls: ['./smslist.component.css']
})
export class SmslistComponent implements OnInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild('row', { static: true }) row: ElementRef;


  public element: any;
  public elements: any;
  public commune: any;
  public insee: any;
  public sms_tags: any;

  public columnDefs: any
  public rowData: any;
  gridOptns = {
    rowHeight : 120
  }

  public gridApi;




  constructor(
      private afAuth: AngularFireAuth,
      public afs: AngularFirestore,
      public router: Router,
      public storage: AngularFireStorage,
      private modalService: BsModalService,
      private accountServiceService: AccountServiceService,
      private renderer: Renderer2,
      private el: ElementRef,
  ) {
  }

  ngOnInit(): void {
    this.insee = this.accountServiceService.getInsee()
    this.commune = this.accountServiceService.getCommune();
    this.getTags();
    this.getUser();
    this.loadDT();
  }




  loadDT(){

    this.columnDefs = [
      {headerName : "Prénom", field : "prenom", width:120, sortable:true,resizable:true, filter:'agTextColumnFilter', filterParams:{newRowsAction:'keep'}},
      {headerName : "Nom", field : "nom", width:120, sortable:true,resizable:true, filter:'agTextColumnFilter', filterParams:{newRowsAction:'keep'}},
      {headerName : "Téléphone", field : "num",width:140, sortable:false, resizable:true, filter:'agTextColumnFilter', filterParams:{newRowsAction:'keep'}},
      {headerName : "Listes Disponibles", field : "listDispo", width:260, cellRendererFramework: CellCustomComponent, resizable:true ,wrapText: true},
      {headerName : "Listes Affectées", field : "listAffect", width:260, cellRendererFramework: CellCustomAffectComponent, wrapText:true, sortable:false,resizable:true},
      {headerName : "Action", field : "Action",resizable:true, sortable:false, cellRendererFramework: CellCustomActionSeeComponent},
    ];


}






  goToFlashs() {
    this.router.navigate(['flash']);
  }

  goToParticipation(){
    this.router.navigate(['participation']);
  }

  goToAdmin(){
    this.router.navigate(['admin']);
  }

  goToSmsList(){
    this.router.navigate(['smslist']);
  }
  goToProfil(){
    this.router.navigate(['profil']);
  }

  getTags() {
    this.afs.collection('communes').doc(this.commune.id).collection('sms_tags').valueChanges({idField: 'propertyId'})
        .subscribe((e) =>
            this.sms_tags = e
        )
  }




  getUser(){
    this.afs.collection('communes').doc(this.commune.id).collection('sms_numbers').valueChanges({idField: 'propertyId'})
        .subscribe((e)=>{
          this.elements = e
        })
  }



  deco() {
    console.log('bye')

    localStorage.clear()
    return this.afAuth.signOut().then(() => {
      this.router.navigate(['login'])
    });
  }


  openModal(template, el){
    console.log('ok')


  }

  supprimer(i){
    this.afs.collection('communes').doc(this.commune.id).collection('sms_numbers').doc(i.propertyId).delete()
  }












}
