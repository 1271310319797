import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cell-custom-action-delete',
  templateUrl: './cell-custom-action-delete.component.html',
  styleUrls: ['./cell-custom-action-delete.component.css']
})
export class CellCustomActionDeleteComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
