<app-main-menu></app-main-menu>



<div class="container sms-container">


    <span id="test">Consulter le nombre d'inscrits :</span>  <a routerLink="/setting-stat">"Réglages & Infos / Onglet Statistiques Communes"</a><br/>
    <br/>
    <ag-grid-angular
    style="height:900px;width: 100%"
    class="ag-theme-alpine"
    [rowData]="elements"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptns"
    ></ag-grid-angular>

</div>
