import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {AngularFireStorage} from '@angular/fire/storage';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AccountServiceService} from '../account-service.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-cell-custom',
  templateUrl: './cell-custom.component.html',
  styleUrls: ['./cell-custom.component.css']
})
export class CellCustomComponent implements OnInit {

  data:any;
  sms_tags : any;
  commune : any;


  constructor(
      private afAuth: AngularFireAuth,
      public afs: AngularFirestore,
      public router: Router,
      public storage: AngularFireStorage,
      private modalService: BsModalService,
      private accountServiceService: AccountServiceService,
      private renderer: Renderer2,
      private el: ElementRef,
  ) { }


  agInit(params){
    this.data = params;
    console.log(params.data)
  }


  ngOnInit(): void {
    this.commune = this.accountServiceService.getCommune();
    this.getTags();
  }

  getTags() {
    this.afs.collection('communes').doc(this.commune.id).collection('sms_tags').valueChanges({idField: 'propertyId'})
        .subscribe((e) =>
            this.sms_tags = e
        )
  }

  tagAction(i, num){

    console.log(i)
    console.log(num)
   console.log(i + ' ' +  num)
    const col = this.afs.collection('communes').doc(this.commune.id).collection('sms_numbers').doc(num);

    this.afs.collection('communes').doc(this.commune.id).collection('sms_send_list_' + i).doc(num).set({
      msisdn: num
    })

    col.update({
      tag_lists : firebase.firestore.FieldValue.arrayUnion(i)
    });

    this.afs.collection('communes').doc(this.commune.id).collection('sms_send_list').doc(i).collection(num).doc(num).valueChanges().subscribe((e)=>{
      console.log(e)
    })

  }

}
