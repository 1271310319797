<section>
    <img class="logo" src="assets/images/logo-mc.png">
    <h1 id="titre">Mairie Connect, simplifiez la communication avec vos habitants</h1>
</section>
<form *ngIf="commune==undefined">
    <section id="form">
        <h2>Administrer ma commune</h2>
        <div class="form-group">
            <label>Code Commune (code INSEE)</label>
            <input type="email" [formControl]="registerForm.controls.code_commune" class="form-control identifiant" required>
        </div>


        <button type="submit" (click)="findCommune()"  class="btn btn-default envoie-form ">Me connecter</button>
</section>
</form>



<form *ngIf="commune!=undefined">
    <section id="form">
        <h2>{{commune.nom}}</h2>
        <div class="form-group">
            <label>Adresse Mail</label>
            <input type="email" [formControl]="registerForm.controls.email" class="form-control identifiant" required>
        </div>
        <div class="form-group">
            <label>Mot de passe</label>
            <input type="password" class="form-control identifiant" [formControl]="registerForm.controls.password" required>
        </div>


        <button type="submit" (click)="logToCommune(commune)"  class="btn btn-default envoie-form ">Me connecter</button>
    </section>
</form>





