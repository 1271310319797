import { Component, OnInit } from '@angular/core';

import {faSync} from '@fortawesome/free-solid-svg-icons';
import {faListOl} from '@fortawesome/free-solid-svg-icons';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {faSms} from '@fortawesome/free-solid-svg-icons';
import {faUsers} from '@fortawesome/free-solid-svg-icons';
import {Router} from '@angular/router';

@Component({
  selector: 'app-sub-menu-settings',
  templateUrl: './sub-menu-settings.component.html',
  styleUrls: ['./sub-menu-settings.component.css']
})
export class SubMenuSettingsComponent implements OnInit {

  constructor(
      public router: Router,
  ) {

  }

  //icons
  faSync = faSync;
  faListOl = faListOl;
  faInfoCircle = faInfoCircle;
  faSms = faSms;
  fausers = faUsers;

  ngOnInit(): void {
  }

  deco(){
    console.log('ok')
  }


  goToProfil(){
    this.router.navigate(['profil']);
  }

  goToSettingStat(){
    this.router.navigate(['setting-stat']);
  }

  goToSettingSms(){
    this.router.navigate(['setting-sms']);
  }

  goToSettingPc(){
    this.router.navigate(['setting-pc']);
  }




}
