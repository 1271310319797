import { Component, OnInit } from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {AngularFireStorage} from '@angular/fire/storage';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AccountServiceService} from '../account-service.service';
import {FormBuilder} from '@angular/forms';

@Component({
  selector: 'app-setting-participation',
  templateUrl: './setting-participation.component.html',
  styleUrls: ['./setting-participation.component.css']
})
export class SettingParticipationComponent implements OnInit {

  constructor(
      private afAuth: AngularFireAuth,
      public afs: AngularFirestore,
      public router: Router,
      public storage: AngularFireStorage,
      private modalService: BsModalService,
      private accountServiceService: AccountServiceService,
      private formBuilder: FormBuilder
  ) { }

  insee:any;
  commune:any;
  pc:any;
  user:any;

  ngOnInit(): void {
    this.insee = this.accountServiceService.getInsee()
    this.commune = this.accountServiceService.getCommune();
    this.getPPStatus();
    this.getUsers()
  }

  getPPStatus(){
    this.afs.collection('communes').doc(this.commune.id).valueChanges().subscribe((e) => {
      this.pc = e['uses_pc'];
    });
  }

  changePP(e){
    console.log(e)
    this.afs.collection('communes').doc(this.commune.id).update({
      uses_pc : e
    })
  }

  getUsers(){
    this.afs.collection('communes').doc(this.commune.id).collection('user').valueChanges().subscribe((e) => {
      this.user = e
    });
  }

  ppNotif(b, selected_user){
    console.log(b.target.checked)
    console.log(selected_user)
    //old method
    this.afs.collection('communes').doc(this.commune.id).collection('user').doc(selected_user).update({
      pp_notif : b.target.checked
    });

    this.afs.collection('m_notif_center').doc(this.commune.id).collection('participation_citoyenne').doc('to_notify').collection('emails').doc(selected_user).set({
      pp_notif : b.target.checked,
      email : selected_user
    });


  }

}
