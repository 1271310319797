import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AccountServiceService} from '../account-service.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {AngularFireStorage} from '@angular/fire/storage';
import {BsModalService} from 'ngx-bootstrap/modal';
import {FormBuilder} from '@angular/forms';
import {async} from 'rxjs';
import {__await} from 'tslib';

@Component({
  selector: 'app-setting-stat',
  templateUrl: './setting-stat.component.html',
  styleUrls: ['./setting-stat.component.css']
})
export class SettingStatComponent implements OnInit, AfterViewInit{

  followers:any;
  followers_only:any;
  follower_global:any;
  followers_only_num:any;
  follower_global_num:any;
  follower_total:number=0;
  sms_followers:any;
  loc_fav:any;

  insee:any
  commune:any


  constructor(
      private afAuth: AngularFireAuth,
      public afs: AngularFirestore,
      public router: Router,
      public storage: AngularFireStorage,
      private modalService: BsModalService,
      private accountServiceService: AccountServiceService,
      private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.insee = this.accountServiceService.getInsee()
    this.commune = this.accountServiceService.getCommune();
    this.refreshFollowers()
  }

  ngAfterViewInit(): void {

  }

  refreshFollowers(){

    var jour = new Date().getDate();
    var mois = ((new Date().getMonth() + 1) < 10 ? '0' : '') + (new Date().getMonth() + 1);
    var annee = new Date().getFullYear()
    var date = jour + '/' + mois + '/' + annee;

    this.afs.collection('communes').doc(this.commune.id).collection('nb_follow', ref=>ref.where('date_reel', '==', date)).valueChanges().subscribe((e)=>{
      console.log(e)
    })


    this.afs.collection('users', ref => ref.where('favoris', '==', {[this.commune.id]:this.commune.nom})).valueChanges().subscribe((e) => {
      this.followers = e
      this.followers_only = e.length
      this.followers_only_num = parseInt(this.followers_only)
      console.log(this.followers_only_num)

    })


    this.afs.collection('users').valueChanges().subscribe((f) => {
      this.follower_global = 0
      f.forEach((elem) => {
        if(elem['favoris']){
          if(elem['favoris'].hasOwnProperty(this.commune.id) == true){
            this.follower_global = this.follower_global+1
            this.follower_global_num = parseInt(this.follower_global)
            console.log(this.follower_global_num)
          }
        }else{

        }
      });
    });

    this.afs.collection('communes').doc(this.commune.id).collection('sms_numbers').valueChanges().subscribe((e) => {
      this.sms_followers = e.length
      console.log(e)
    })





    this.recordNb(date, this.follower_global, this.followers_only)

  }

  async recordNb(d, fg, fo){

    console.log(d)
    console.log(Date.now())
    console.log(fg)
    console.log(fo)


    this.afs.collection('communes').doc(this.commune.id).collection('nb_follow').add({
      date_reel: d,
      date_ts: Date.now(),
      global: await fg,
      unique: await fo
    });
  }




}
