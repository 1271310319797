import {AfterViewInit, Component, OnInit} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFirestore} from '@angular/fire/firestore';
import {Router} from '@angular/router';
import {AngularFireStorage} from '@angular/fire/storage';
import {BsModalService} from 'ngx-bootstrap/modal';
import {AccountServiceService} from '../account-service.service';

@Component({
  selector: 'app-flashs',
  templateUrl: './flashs.component.html',
  styleUrls: ['./flashs.component.css']
})
export class FlashsComponent implements OnInit, AfterViewInit {
  public commune:any;
  public posts:any;
  public insee;
  public posts_ref:any;

  constructor(
      private afAuth: AngularFireAuth,
      public afs: AngularFirestore,
      public router: Router,
      public storage: AngularFireStorage,
      private accountServiceService: AccountServiceService
  ) {

  }

  ngOnInit(): void {
    this.getCommune()

    /*setTimeout((e) => {
      this.getCityFlashs()
    },20);*/



  }

  ngAfterViewInit() {
    this.getCityFlashs()

  }


  getCommune(){

    this.insee = this.accountServiceService.getInsee()
    this.commune = this.accountServiceService.getCommune()

    this.afAuth.authState.subscribe((user) =>{
      console.log(user)
      this.afs.collection('communes', ref => ref.where('insee', '==', this.insee))
          .valueChanges({idField: 'id'}).subscribe((e) =>
          this.commune = e[0],
      )

    }, (error) => {
      console.log(error)
    });


  }

  async getCityFlashs(){
    console.log("JE SUIS LA !!!!!!!!")
    this.posts_ref =  this.afs.collection('communes').doc(this.commune.id).collection('infos', ref => ref.orderBy('date_ts', 'desc'))
    this.posts_ref.valueChanges({idField: 'propertyId'})
        .subscribe((e) =>
            this.posts = e
        );


  }

  activerPost(post){
    console.log(post)
    this.afs.collection('communes').doc(this.commune.id).collection('infos').doc(post.propertyId).update({is_displayed: true})
  }

  desactiverPost(post){
    console.log(post)
    this.afs.collection('communes').doc(this.commune.id).collection('infos').doc(post.propertyId).update({is_displayed: false})
  }

  deletePost(post){
    console.log(post)
    this.afs.collection('communes').doc(this.commune.id).collection('infos').doc(post.propertyId).delete()
  }

  //*********** MENU MENU MENU

  goToFlashs(){
    this.router.navigate(['flash']);
  }

  goToParticipation(){
    this.router.navigate(['participation']);
  }

  goToAdmin(){
    this.router.navigate(['admin']);
  }

  goToSmsList(){
    this.router.navigate(['smslist']);
  }
  goToProfil(){
    this.router.navigate(['profil']);
  }




  deco(){

    console.log('bye')

    localStorage.clear()
    return this.afAuth.signOut().then(()=>{
      this.router.navigate(['login'])
    });

  }



//*******************************

}
