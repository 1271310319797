<app-main-menu></app-main-menu>
<div class="row">
    <div class="col-md-6 grey">

        <div *ngIf="choix">
            <h1 class="titre_box">Que souhaitez vous faire ?</h1>

            <!--<button (click)="test()">TEST</button>-->

            <button (click)="chageState(1)" type="button" class="btn btn-primary btn-lg btn-block btn-question">Flash Info Mairie Connect</button>
            <button (click)="chageState(2)" type="button" class="btn btn-primary btn-lg btn-block btn-question">Flash Info SMS</button>
            <!--<button (click)="chageState(3)" type="button" class="btn btn-primary btn-lg btn-block btn-question">Les deux</button>-->
        </div>


        <div *ngIf="mairie_connect">
            <h1 class="titre_box">Publier un Flash Info Mairie Connect</h1>
            <form>
                <div class="form-group">
                    <label>Titre</label>
                    <input type="email" [formControl]="fiForm.controls.titre" class="form-control connexion" required>
                </div>
                <div class="form-group">
                    <label>Ajouter une image</label>
                    <input type="file" class="form-control connexion" (change)="addPhoto($event)" name="image">
                </div>

                <div class="form-group">
                    <label>Contenu Mairie Connect</label>
                     <quill-editor [formControl]="fiForm.controls.description" [modules]="config"></quill-editor>
                </div>

                <!--<input onclick='publier()' class="envoie-form" type="submit" id='submit' value='PUBLIER' >-->
                <button style=" width:100%" (click)="openModal(template, 1)" type="button" class="btn btn-primary envoie-form" data-toggle="modal" data-target="#exampleModal">
                    PUBLIER
                </button>

                <ng-template #template>
                        <div class="modal-header">
                            <h4 *ngIf="isSent==false" class="modal-title pull-left">Confirmez vous l'envoi ?</h4>
                            <h4 *ngIf="isSent==true" class="modal-title pull-left">Votre flash info est publié</h4>
                            <button type="button" class="close pull-right"
                                    aria-label="Close" (click)="modalRef.hide()">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div *ngIf="isSent==false" class="modal-body">
                            <div class="col-md-5" class="div-apercu" style="margin-bottom: 2em;">
                                <h2 class="apercu">Aperçu avant publication</h2>
                                <div style="position:relative" class="preview">
                                    <h1>{{fiForm.controls.titre.value}}</h1>
                                    <div class="text-prev" [innerHTML]="fiForm.controls.description.value"></div>
                                    <img class="img-prev" [src]="fb"/>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-sm-6"> <button type="submit" (click)="publier()"  class="btn btn-default me-connecter">PUBLIER Maintenant</button></div>
                                <div class="col-sm-6"> <button type="submit" (click)="modalRef.hide(); openModal(plannifier)"  class="btn btn-default me-connecter">PLANNIFIER la publication</button></div>
                            </div>
                        </div>
                    <div *ngIf="isSent==true" class="modal-body">
                        <img style="width: 50%" src="assets/images/published.gif">
                    </div>
                </ng-template>

                <ng-template #plannifier>
                    <div class="modal-header">
                        <h4 *ngIf="isSent==false" class="modal-title pull-left">Flash Info Plannifié</h4>
                        <button type="button" class="close pull-right"
                                aria-label="Close" (click)="modalRef.hide()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div *ngIf="isSent==false" class="modal-body">
                        <div class="col-md-5" class="div-apercu" style="margin-bottom: 2em;">
                            <h2 class="apercu">Quand souhaitez vous envoyer votre Flash ?</h2>

                            <mat-form-field>
                                <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" [formControl]="fiForm.controls.pubDate"
                                       [min]="minDate" [max]="maxDate" [disabled]="disabled">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [showSeconds]="showSeconds" [stepHour]="stepHour"
                                                         [stepMinute]="stepMinute" [stepSecond]="stepSecond" [touchUi]="touchUi" [color]="color"
                                                         [enableMeridian]="enableMeridian" [disableMinute]="disableMinute" [hideTime]="hideTime">
                                    <!-- Custom icon or text of Apply icon -->
                                    <ng-template>
                                        <!-- <mat-icon>star</mat-icon> -->
                                        <span>OK</span>
                                    </ng-template>
                                </ngx-mat-datetime-picker>
                            </mat-form-field>
                        </div>
                        <div class="row">


                            <div class="col-sm-12 col-md-12"> <button type="submit" (click)="publier_later()"  class="btn btn-default me-connecter">PLANNIFIER la publication</button></div>

                        </div>


                    </div>


                    <div *ngIf="isSent==true" class="modal-body">
                        <img style="width: 50%" src="assets/images/published.gif">
                    </div>



                </ng-template>

            </form>
        </div>
        <div *ngIf="s_ms">
            <h1 class="titre_box">Publier un SMS</h1>
            <form>

                <div class="form-group">
                    <label>Contenu SMS</label><br/>
                    <p>Nombre de caractères : <b>{{smsLenghtTotal}}</b> | Nombre de SMS : <b>{{nbSms}}</b> | <span (click)="openModal(prix_sms)">plus d'infos</span>
                    <textarea (keyup)="countCaractSms()" class="ta_sms" [formControl]="fiForm.controls.smsContent"></textarea>
                </div>

                <!--<input onclick='publier()' class="envoie-form" type="submit" id='submit' value='PUBLIER' >-->
                <button style=" width:100%" (click)="openModal(t_sms, 2)" type="button" class="btn btn-primary envoie-form" data-toggle="modal" data-target="#exampleModal">
                    Choisir les destinataires et envoyer
                </button>

                <ng-template #t_sms>
                    <div class="modal-header">
                        <h4 *ngIf="isSent==false" class="modal-title pull-left">Confirmez vous l'envoi ?</h4>
                        <h4 *ngIf="isSent==true" class="modal-title pull-left">Votre flash info est publié</h4>
                        <button type="button" class="close pull-right"
                                aria-label="Close" (click)="modalRef.hide()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div *ngIf="isSent==false" class="modal-body">
                        <div class="col-md-12" style="margin-bottom: 2em;">
                            <h2 class="apercu">Aperçu de votre SMS</h2>
                            <div style="position:relative" class="preview">
                                <div class="text-prevSMS" [innerHTML]="fiForm.controls.smsContent.value"></div>
                                <img class="img-prev" [src]="fb"/>

                            </div>
                        </div>
                        <div class="col-md-12"  style="margin-bottom: 2em;">
                            <h2 class="apercu">À qui envoyer ce sms ?</h2>
                            <div style="position:relative" class="preview">
                                <div *ngFor="let tags of sms_tags; let i=index">
                                    <label>
                                        <input class="case-liste-envoi" type="checkbox" [value]="tags.value" (change)="oncheckChange($event)" />{{tags.value}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <button type="submit" (click)="publier_sms()"  class="btn btn-default me-connecter">Envoyer le SMS</button>
                    </div>


                    <div *ngIf="isSent==true" class="modal-body">
                        <img style="width: 50%" src="assets/images/published.gif">
                    </div>
                </ng-template>


                <ng-template #prix_sms>
                    <div class="modal-header">
                        <h1>Prix des SMS</h1>
                    </div>
                    <div class="modal-body">
                        <h4>Pourquoi le nombre de caractères est supérieur à 0 alors que je n'ai rien rédigé ?</h4>
                        <p>Le nombre de caractère compte également l'entête ainsi que le pied de SMS. Pour votre commune, l'entête est <b>Commune de {{commune.nom}}</b> et le pied est <b>STOP SMS : {{commune.mairie_tel}}</b>.
                    </div>

                </ng-template>
            </form>
        </div>

        <div *ngIf="les_deux">

            <h1 class="titre_box">Publier un Flash Info Mairie Connect + SMS</h1>

            <form>
                <div class="form-group">
                    <label>Titre</label>
                    <input type="email" [formControl]="fiForm.controls.titre" class="form-control connexion" required>
                </div>

                <div class="form-group">
                    <label>Ajouter une image (uniquement Mairie Connect)</label>
                    <input type="file" class="form-control connexion" (change)="addPhoto($event)" name="image">
                </div>

                <div class="form-group">
                    <label>Contenu Mairie Connect</label>
                    <quill-editor [formControl]="fiForm.controls.description" [modules]="config"></quill-editor>
                </div>

                <div class="form-group">
                    <label>Contenu SMS</label>
                    <textarea class="ta_sms" [formControl]="fiForm.controls.smsContent"></textarea>
                </div>

                <!--<input onclick='publier()' class="envoie-form" type="submit" id='submit' value='PUBLIER' >-->
                <button style=" width:100%" (click)="openModal(template)" type="button" class="btn btn-primary envoie-form" data-toggle="modal" data-target="#exampleModal">
                    PUBLIER
                </button>

                <ng-template #template>



                    <div class="modal-header">
                        <h4 *ngIf="isSent==false" class="modal-title pull-left">Confirmez vous l'envoi ?</h4>
                        <h4 *ngIf="isSent==true" class="modal-title pull-left">Votre flash info est publié</h4>
                        <button type="button" class="close pull-right"
                                aria-label="Close" (click)="modalRef.hide()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div *ngIf="isSent==false" class="modal-body">
                        <div class="col-md-5" class="div-apercu" style="margin-bottom: 2em;">
                            <h2 class="apercu">Aperçu de votre Flash Info :</h2>
                            <div style="position:relative" class="preview">

                                <h1>{{fiForm.controls.titre.value}}</h1>
                                <div class="text-prev" [innerHTML]="fiForm.controls.description.value"></div>
                                <img class="img-prev" [src]="fb"/>

                            </div>
                        </div>


                        <button type="submit" (click)="publier_2()"  class="btn btn-default me-connecter">PUBLIER Mairie Connect + SMS</button>
                    </div>


                    <div *ngIf="isSent==true" class="modal-body">
                        <img style="width: 50%" src="assets/images/published.gif">
                    </div>



                </ng-template>
            </form>
        </div>



        <div *ngIf="s_ms">

        </div>

        <div *ngIf="les_deux">


        </div>

    </div>

    <div class="col-md-5" class="div-apercu">
        <h2 class="apercu">Aperçu de votre Flash Info Mairie Connect :</h2>
        <div style="position:relative" class="preview">

            <h1>{{fiForm.controls.titre.value}}</h1>
            <div class="text-prev" [innerHTML]="fiForm.controls.description.value"></div>
            <img class="img-prev" [src]="fb"/>

        </div>
    </div>


</div>











